

import React from 'react';
import { motion } from 'framer-motion';
import './App.css';
import latteCoin from './1.jpg'; 

function App() {
  return (
    <div className="min-h-screen bg-memeDark text-white font-memeFont">
      <header className="flex justify-between items-center p-6 bg-memeGreen">
        <motion.h1
          initial={{ x: '-100vw' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 120 }}
          className="text-5xl font-bold"
        >
          $KOTETSU 
        </motion.h1>
        <nav className="flex space-x-4">
          <a href="#chart" className="hover:text-memeGreen">Chart</a>
          <a href="#telegram" className="hover:text-memeGreen">Telegram</a>
          <a href="#twitter" className="hover:text-memeGreen">Twitter</a>
          <a href="#contract" className="hover:text-memeGreen">Contract</a>
        </nav>
      </header>

      <main className="flex flex-col items-center justify-center space-y-10 py-20">
        <motion.div
          initial={{ x: '-100vw' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 120 }}
          className="text-center"
        >
          <h2 className="text-6xl font-extrabold text-memeGreen">$Kotetsu CTO</h2>
          <p className="mt-4 text-xl">
            Kabosu $DOGE is the king of all dog memes, its best friend Kotetsu should giga send from here!
          </p>
        </motion.div>

        <motion.img
          src={latteCoin}
          className="w-1/2 max-w-md"
          alt="Kabosu Meme"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 1 }}
          className="flex space-x-6"
        >
          <a href="https://dexscreener.com/ethereum/0xaf20135adc235322e65e42b65d5ab96ad152234f" className="bg-memeGreen px-6 py-3 rounded-lg hover:bg-white hover:text-memeGreen transition transform hover:scale-105">View Chart</a>
          <a href="https://t.me/KotetsuCTO_ERC20" className="bg-memeGreen px-6 py-3 rounded-lg hover:bg-white hover:text-memeGreen transition transform hover:scale-105">Join Telegram</a>
          
          <a href="https://x.com/KOTETSUCTO" className="bg-memeGreen px-6 py-3 rounded-lg hover:bg-white hover:text-memeGreen transition transform hover:scale-105">View Twitter</a>
        </motion.div>
      </main>

      <footer className="text-center py-8">
        <h4 id="contract" className="text-xl">Contract: 0xaf20135ADc235322E65e42B65D5Ab96AD152234f</h4>
        <p className="mt-4">Follow us on Twitter for updates!</p>
        <a href="https://x.com/KOTETSUCTO" className="text-memeGreen hover:text-white">Twitter</a>
      </footer>
    </div>
  );
}

export default App;
